<template>
  <Layout>
    <p>
      Quis laborum nostrud proident cupidatat tempor officia.Velit tempor
      incididunt nostrud commodo ea adipisicing.
    </p>
  </Layout>
</template>

<script>
import Layout from '../components/Layout.vue';
export default {
  name: 'About',
  components: {
    Layout,
  },
};
</script>
